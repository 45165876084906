_cruxUtils._cruxLocale = {
"crux.no.bc.message":"Nije dostupan pregled. Kliknite na zapis da biste vidjeli dodatne pojedinosti",
"crm.appointments.status.markcompleted1": "Ozna\u010di kao dovr\u0161eno",
"crm.mass.actions.all.selected": "<span class=\"dIB vaM\">Svi </span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'> {0}\u00a0</span><span class=\"dIB vaM\"> {1} u ovom prikazu su odabrani.</span>",
"crm.module.selectall": "Odaberite sve {0} u ovom prikazu",
"records": "zapisi",
"crm.record.not.accessible": "zapis",
"crm.view.calls.recording.err.msg": "Snimka glasa nema URL koji se mo\u017ee reproducirati.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1} odabrano.</span>",
"totalrecords": "Ukupno zapisa",
"crm.listview.options.text.clip": "Izre\u017ei tekst",
"crm.listview.options.text.wrap": "Tekst sa\u017eetka",
"crm.listview.options.reset.width": "Resetiraj veli\u010dinu stupca",
"crm.listview.options.manage": "Upravljaj stupcima",
"crm.listview.customview.locked": "Stupci se ne mogu prilagoditi jer je ovaj prikaz zaklju\u010dan",
"crm.api.apidashboard.SortBy": "Sortiraj po",
"crm.privacy.listview.consent.locked": "Blokirana je obrada zapisa",
"crm.label.reschedule.call": "Zaka\u017ei ponovno {0}",
"crm.button.edit": "Uredi",
"Delete": "Izbri\u0161i",
"crm.appointments.status.markcancel": "Otka\u017ei {0}",
"crm.appointments.status.markreschedule": "Zaka\u017ei ponovno {0}",
"crm.label.cancel.call": "Otka\u017ei {0}",
"crm.label.mark.completed": "Ozna\u010di kao dovr\u0161eno",
"crm.listview.customview.locked": "Stupci se ne mogu prilagoditi jer je ovaj prikaz zaklju\u010dan",
"pf.deleted.state": "Izbrisano stanje",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "Sinkronizirajte s {0}",
"crm.schedules.prefix.in": "U",
"crm.record.record.locked.other": "Zapis je zaklju\u010dan za druge korisnike profila.",
"crm.record.lock.record.locked": "Zapis je zaklju\u010dan.",
"crm.approvalProcess.label.waitingForApproval": "\u010ceka na odobrenje",
"crm.reviewprocess.record.review.pending": "Treba pregledati",
"crm.approvalProcess.label.waitingForFindAndMerge": "U tijeku je spajanje duplikata.",
"crm.privacy.listview.consent.locked": "Blokirana je obrada zapisa",
"crm.zia.vision.processing": "Zia provjerava valjanost slika.",
"crm.zia.vision.rejected.msg": "Zapis je odbijen zbog gre\u0161ke u provjeri valjanosti slike.",
"crm.zia.vision.record.failure.msg": "Zapis \u010deka odobrenje zbog gre\u0161ke u provjeri valjanosti slike.",
"crm.alert.max.cvcols.reached": "Mo\u017eete odabrati maksimalno {0} stupaca.",
"crm.label.small.tags": "Oznake",
"crm.label.edit.module": "Uredi {0}",
"crm.social.integ.no.result": "Nema rezultata",
"custommodule.crmfield.fl.name": "{0} Ime",
"Appointment": "Termin",
"crm.button.create.call": "Stvori poziv",
"crm.module.create": "Stvori {0}",
"crm.customview.activty.badge": "Zna\u010dka aktivnosti",
"crm.button.callnow": "Pozovi odmah",
"crm.manualcalllist.complete.call": "Prijava poziva",
"crm.label.schedule.call": "Zaka\u017ei poziv",
"crm.filter.label.replied":"odgovorio",
"bytes": "bajtova",
"crm.field.label.user.lookup": "Korisnik",
"crm.translation.refresh.cases": "Radnja koju poku\u0161avate izvesti nije se mogla dovr\u0161iti. Osvje\u017eite stranicu i poku\u0161ajte ponovno.",
"crm.project.sync.notes.info": "Prikazat \u0107e se komentari zadataka povezanih Projekata",
"crux.note.content.limit.warning.msg":"Dopu\u0161teno je spremiti do {0} znakova u sadr\u017eaju bilje\u0161ki",
"crux.note.formatting.options":"Opcije formatiranja",
"crm.label.required": "Obavezno",
"crux.condition.not.belongs.to":"ne pripada {0}",
"crm.project.sync.notes.info": "Prikazat \u0107e se komentari zadataka povezanih Projekata",
"crm.sf.permission.no.edit": "Nemate dopu\u0161tenje za a\u017euriranje unosa.",
"crm.sf.permission.no.create": "Nemate dozvolu za dodavanje unosa.",
"crm.sf.permission.no.delete": "Nemate dopu\u0161tenje za brisanje unosa.",
"crux.note.this.note":"ova napomena",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "Uloga prijavljenog korisnika",
"Unit Price": "Jedini\u010dna cijena",
"Qty in Stock": "Koli\u010dina na stanju zaliha",
"crm.label.Product Code": "K\u00f4d proizvoda",
"TotalAfterDiscount": "Ukupno nakon popusta",
"crm.lookupfilter.entity.errormsg": "Odabrani {0} ne zadovoljava kriterije tra\u017eenja.",
"crm.iamexception.maxlen": "Ne mo\u017eete unijeti vi\u0161e od {0} znakova za {1}",
"crm.record.locking.permission.denied": "Ova radnja ne mo\u017ee se izvesti jer je zapis zaklju\u010dan.",
"List Price": "Cjenik",
"crm.label.no.more.records": "Nema vi\u0161e zapisa",
"crm.workflow.rule.created.dateAndTime":"{0} na {1} u {2}",
"crux.new.note.msg":"\u0160to je svrha ove napomene?",
"crm.image.uploaded":"Slika je u\u010ditana",
"crm.imageupload.drag.drop.here":"Povucite i ispustite svoje slike ovdje",
"crm.fileupload.drag.drop.here":"Ovdje povucite i ispustite datoteke ovdje",
"crm.fileupload.wait.msg":"\u010cekajte da se u\u010ditaju odabrane datoteke",
"crm.fileupload.attach.fail":"Neuspjeli prilog",
"crm.fileupload.support.format":"Podr\u017eava format datoteke (jpeg, jpg, png, pdf) za Zia obuku",
"custmr.prtl.user.role": "Korisnik portala",
"crm.subform.empty.alert.header":"Prazan red ne mo\u017ee se spremiti",
"crm.crud.lookup.module.inaccessible": "Nemate dozvolu pristupa ovom modulu pregledavanja",
"crm.crud.lookup.inaccessible.record": "Zapis ne postoji ili cxVam je nedostupan",
"crm.custombutton.valid.weburl.check": "Unesite neku vrijednost.",
"crm.label.subform.goto.top": "Idi na vrh popisa",
"crm.label.subform.row.show.all": "Poka\u017ei sve ({0})",
"cpq.pr.nodiscount": "Nije odvojen popust",
"crm.inventory.lineitem.no.pricebook.new": "Nisu povezani {0}.",
"crm.label.subform.allowedlimit": "Dopu\u0161tena granica od {0} dosegnutih redaka",
"crm.reports.integ.okay": "U redu",
"crm.crud.subform.deleted.record": "A\u017eurirani redovi podobrasca ve\u0107 su izbrisani. Osvje\u017eite stranicu i poku\u0161ajte ponovno.",
"crux.criteria.fieldlabel.valid.check":"Unesite valjanu oznaku polja",
"crm.subform.delete.confirm.header":"Izbriši redak podobrasca",
"crm.subform.delete.confirm.msg": "Jeste li sigurni da želite izbrisati odabrani red u {0}?",
"crm.subform.delete.tooltip.msg" :"Red se ne može izbrisati. Za {0} je potreban unos barem jednog reda.",
	"crm.condition.belongs.to":"pripada {0}",//no i18n
"crm.condition.not.belongs.to":"ne pripada {0}",//no i18n
"crm.orchestration.error.selectvalue": "Odaberite vrijednost",
	"crm.attach.upload.image":"Prilo\u017ei slike",//no i18n
"crm.general.select.photo":"Odaberi sliku",//no i18n
"crm.imageupload.wait.msg":"\u010cekajte da se u\u010ditaju odabrane slike.",//no i18n
"crm.image.filepicker.desc.draganddrop":"Povucite i ispustite slike.",//no i18n
"crm.image.filepicker.desc.browse":"Kliknite na pregledavanje slika...",//no i18n
"crm.attach.upload.userinfo":"Ukupna veli\u010dina ograni\u010dena je na <span class",//no i18n
"crm.image.supported.formats":"Podr\u017eani formati: JPEG, PNG, GIF i BMP.",//no i18n
"crm.imageupload.allowed.field.length":"Mo\u017eete u\u010ditati maksimalno ovoliko slika: {0}.",//no i18n
"crm.general.crop.and.set":"Izre\u017ei i podesi",//no i18n
"crm.attach.option.label":"Prilo\u017ei",//no i18n
"crm.image.crop.and.rotate":"Izre\u017ei i rotiraj",//no i18n
"crm.image.resolution":"Rje\u0161enje",//no i18n
"crm.attachment.size":"Veli\u010dina",//no i18n
"crm.label.close":"Zatvori",//no i18n
"crm.label.previous":"Prethodno",//no i18n
"crm.label.next":"Sljede\u0107e",//no i18n
"crm.subform.record.create.maxrow.limit": "Maksimalno {0} zapisa dozvoljeno je u {1}",
"crm.label.subform.addrows": "Dodaj red",
"crm.tax.association.check": "Nema poreza povezanih s ovim proizvodom.",
	"crm.gallery.inventory.template.discount": "Popust",
	"crm.inventory.discount.scheme.range": "Raspon",
	"Description": "Opis",
	"crm.inventory.lineitem.no.pricebook": "Nema pridru\u017eenih cjenika.",
	"crm.recordImage.previewimage":"Pretpregled slike",//no i18n
"crm.FileuploadField.addNewImage":"U\u010ditaj sliku",//no i18n
"crm.fileuploader.removefile":"Ukloni",//no i18n
	"voc.gc.configure":"Konfiguriraj",//no i18n
"Edit":"Uredi",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "Navedite odgovaraju\u0107u vrijednost za {0}",
	"crm.label.field":"Polje",//no i18n
"crm.label.value":"Vrijednost",//no i18n
	"crm.button.clone":"Kloniraj",//no i18n
	"sentiment.criteria.wrongcriteria":"Vrijednost kriterija ne mo\u017ee biti ve\u0107a od {0}",//no i18n
"crm.mb.field.common.splc":"Nisu dopu\u0161teni posebni znakovi. Unesite valjanu vrijednost.",//no i18n
	"crm.view.attachment.download":"Preuzmi",//no i18n
"crm.label.view":"Prika\u017ei",//no i18n
	"crm.label.field.plural":"polja",//no i18n
	"crm.label.in.minutes":"{0} (U minutama)",//no i18n
	"crm.security.roles.list":"Popis uloga",//no i18n
"crm.security.roles.lookup.info":"Odaberite jednu ulogu s popisa.",//no i18n
"crm.territory.addterritory":"Dodaj teritorij",//no i18n
"crm.title.edit.territory":"Uredi teritorij",//no i18n
"crm.territory.title.assign.territories":"Dodijeli teritorije",//no i18n
	"crm.label.context.help":"Pomo\u0107",//no i18n
	"crm.label.from":"Od",//no i18n
"crm.label.to":"Prima",//no i18n
	"workflow.option.webhookFailure.fromDate":"Datum Od",//no i18n
"workflow.option.webhookFailure.toDate":"Do datuma",//no i18n
"crm.custom.field.less.than.equalto":"{0} treba biti manje od ili jednako {1}.",//no i18n
	"crm.template.listview.search.no.results":"Nema rezultata",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"Datum Od ne mo\u017ee biti ve\u0107i od datuma Do.",//no i18n
	"crm.label.tag.new":"Nova oznaka",//No I18n
	"crm.label.enter.tag":"Unesi oznake",//No I18n
	"crux.comboBox.max.limit":"Ne mo\u017eete odabrati vi\u0161e od {0} {1}.",//NO I18n
	"Administrator":"Administrator",//No I18n
	"Standard":"Standardno",//No I18n
	"crm.button.add":"Dodaj",//NO I18n
	"crm.label.users":"Korisnici", //NO I18n
  "crm.workflow.alert.roles":"Uloge", //NO I18n
  "crm.security.groups":"Grupe", //NO I18n
	"crm.label.available" : "Dostupno", //NO I18n
	"crm.label.assign.manually" : "Dodijeli", //NO I18n
	"crm.globalsearch.option.all": "Sve", //NO I18n
	"webform.status.Active":"Aktivan", //NO I18n
	"Inactive":"Neaktivno", //NO I18n
  "Confirmed":"Potvr\u0111eno", //NO I18n
  "crm.user.component.unconfirmed":"Nepotvr\u0111eno",//no i18n
  "DeletedUser":"Izbrisano", //NO I18n
  "crm.feed.group.admin":"Administrator", //NO I18n
  "crm.ln.lable.current":"Trenutno", //NO I18n
	"crm.label.selected": "Odabrano",//NO I18n
	"crm.auditlog.user": "Korisnik", //NO I18n
	"cob.role": "Uloga", //NO I18n
	"zoho.email": "E-po\u0161ta", //NO I18n
	"Profile": "Profil", //NO I18n
	"crm.security.group.users.empty": "Nema prona\u0111enih korisnika.", //NO I18n
	"crm.label.picklist.none": "Nema", //NO I18n
	"crm.usrpop.non.selected" : "Odabrani korisnici",//NO I18n
	"crm.zti.label.user": "Korisni\u010dko ime",//No I18n
	"crm.label.notSelected" : "Nije odabrano",//NO I18n
	"AM" : "prije podne",//NO I18n
	"Call" : "Poziv",//NO I18n
	"crm.phoneNo.Link.Title" : "Poziv pomo\u0107u Skype servisa",//NO I18n
	"crm.no.data.found" : "Nema prona\u0111enih podataka.",//NO I18n
	"crm.label.no.options.found" : "Nema prona\u0111enih opcija.",//No I18n
	"crm.globalsearch.search.title" : "Pretra\u017ei",//No I18n
	"None" : "Nema",//No I18n
	"crm.label.criteria.pattern" : "Uzorak kriterija",//No I18n
	"crm.label.edit.criteria.pattern" : "Uredi obrazac",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Zagrade uzorka ne odgovaraju.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Neva\u017ee\u0107e zagrade koje okru\u017euju djelatnika.",//No I18n
	"crm.criteria.number.notmatch.check" : "Provjerite uzorak na {0}.",//No I18n
	"criteria.error.alert.other.params" : "Nevaljani sadr\u017eaj u ovom uzorku.", //No I18n
	"crm.label.search.for.users": "Pretra\u017ei korisnike", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "Obrazac kriterija ne odgovara odabranim uvjetima.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "Obrazac kriterija ne odgovara odabranim uvjetima.", //No I18n
	"and" : "i", //No I18n
	"or" : "ili", //No I18n
	"crm.label.or" : "ILI", //No I18n
	"crm.label.and" : "I", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Unesite valjanu oznaku polja u retku {0}.", //No I18n
	"crm.criteria.condition.valid.check" : "Navedite valjani uvjet za {0}.", //No I18n
	"crm.field.valid.check" : "Unesite valjani {0}", //No I18n
	"crm.custom.field.less.than.to" : "<i>Od</i> raspona ne mo\u017ee biti ve\u0107i od raspona <i>Do</i>.", //No I18n
	"crm.alert.label.savepattern" : "Spremi obrazac prije promjene kriterija.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Ne mo\u017eete dodati dodatni kriterij.",//No I18n
	"is" : "je",//No I18n
	"isn\'t" : "nije",//No I18n
	"contains" : "sadr\u017ei",//No I18n
	"doesn\'t contain" : "ne sadr\u017ei",//No I18n
	"starts with" : "po\u010dinje s",//No I18n
	"ends with" : "zavr\u0161ava s",//No I18n
	"is empty" : "prazno je",//No I18n
	"is not empty" : "nije prazno",//No I18n
	"is before" : "je prije",//No I18n
	"is after" : "je poslije",//No I18n
	"between" : "izme\u0111u",//No I18n
	"not between" : "nije izme\u0111u",//No I18n
	"Today" : "Danas",//No I18n
	"Tommorow" : "Sutra",//No I18n
	"Tommorow Onwards" : "Po\u010dinje sutra",//No I18n
	"Yesterday" : "Ju\u010der",//No I18n
	"Till Yesterday" : "Do ju\u010der",//No I18n
	"Last Month" : "Posljednji mjesec",//No I18n
	"Current Month" : "Ovaj mjesec", //No I18n
	"Next Month" : "Sljede\u0107i mjesec", //No I18n
	"Last Week" : "Pro\u0161li tjedan", //No I18n
	"Current Week" : "Ovaj tjedan", //No I18n
	"Next Week" : "Sljede\u0107i tjedan", //No I18n
	"Age in Days" : "Dob u danima", //No I18n
	"Due in Days" : "Dospijeva za dana", //No I18n
	"Scheduled" : "Planirano", //No I18n
	"Attended Dialled" : "Nadziran birani broj", //No I18n
	"Unattended Dialled" : "Nenadziran birani broj", //No I18n
	"Overdue" : "Prekora\u010deni rok", //No I18n
	"Cancelled" : "Otkazano", //No I18n
	"Received" : "Primljeno", //No I18n
	"Missed" : "Propu\u0161teno", //No I18n
	"crm.alert.character.not.allowed" : "{0} nije dopu\u0161ten", //No I18n
	"crm.condition.in.last" : "u posljednjih", //No I18n
	"crm.zinvoice.dueIn" : "dospijeva za", //No I18n
	"on" : "Na",//No I18n
	"before" : "prije",//No I18n
	"crm.label.general.small.after" : "nakon",//No I18n
	"crm.thisweek" : "Ovaj tjedan",//No I18n
	"crm.label.this.month" : "Ovog mjeseca",//No I18n
	"crm.thisyear" : "Ova godina",//No I18n
	"crm.source.user.and.system" : "Korisnik i sustav",//No I18n
	"crm.source.user.or.system" : "Korisnik ili sustav",//No I18n
	"crm.label.system2" : "Sustav",//No I18n
	"crm.source.user.only" : "Samo po korisnicima",//No I18n
	"crm.source.system.only" : "Samo preko sustava",//No I18n
	"crm.condition.till.today" : "Do danas",//No I18n
	"game.month.left" : "1 mjesec",//No I18n
	"game.months.left" : "{0} mjeseci",//No I18n
	"crm.condition.last.30.days" : "posljednjih 30 dana",//No I18n
	"crm.condition.last.60.days" : "posljednjih 60 dana",//No I18n
	"crm.condition.last.90.days" : "posljednjih 90 dana",//No I18n
	"crm.label.filter.typehere" : "Upi\u0161ite ovdje", //No I18N
	"crm.filter.is.not" : "nije", //No I18n
	"crm.condition.until.now" : "Do sada",//No I18n
	"crm.filter.email.isblocked" : "blokirano",//No I18n
	"crm.filter.email.isnotblocked" : "nije blokirano",//No I18n
	"crm.label.no.results.match" : "Nisu prona\u0111eni rezultati",//No I18n
	"crm.label.select.user" : "Kliknite za odabir korisnika.", //No I18n
	"current.logged.in.user": "Prijavljeni korisnik", //NO I18n
	"current.logged.in.user.definition": "Korisnik koji pokre\u0107e radnju zapisa.", //NO i18n
	"crm.security.group": "Grupa", //NO I18n
	"crm.security.role": "Uloga", //NO I18n
	"Date" : "Datum",//No I18n
	"crm.field.valid.decimal.check2" : "Decimalna mjesta za  <i>{0}</i> polje trebaju biti manja ili jednaka {1}.",//No I18n
	"crm.field.empty.check" : "{0} ne mo\u017ee biti prazno.",//No I18n
	"crm.label.add.note": "Dodaj napomenu", //NO I18n
	"crm.label.simply.by": "izvr\u0161io", //NO I18n
	"crm.general.addnote": "Dodaj napomenu", //NO I18n
	"crm.general.addtitle": "Dodaj naslov", //NO I18n
	"crm.label.attach.file": "Prilo\u017ei datoteku", //NO I18N
	"crm.button.cancel": "Otka\u017ei", //NO I18N
	"crm.button.save": "Spremi", //NO I18N
	"crm.button.mass.delete": "Izbri\u0161i", //NO I18N
	"crm.warning.delete.record": "Sigurni ste da \u017eelite izbrisati \u201e{0}\u201c?", //NO I18N
	"crm.label.yes": "Da", //NO I18N
	"crm.note.view.previous": "Prika\u017ei prethodne napomene", //NO I18N
  "of": "od", //NO I18N
	"crm.label.notes": "Napomene", //NO I18N
	"crm.note.recent.first": "Nedavno prvi", //NO I18N
	"crm.note.recent.last": "Nedavno posljednji", //NO I18N
	"crm.territory.label.only": "{0} samo", //no i18n
	"crm.select" : "Odaberi",//No I18n
	"crm.button.apply.filter" : "Primijeni filtar",//No I18n
	"crm.alert.maximum.text.values.contains" : "Ne mo\u017eete unijeti vi\u0161e od {0} vrijednosti za ovo polje.",//No I18n
	"PM" : "poslije podne",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "Duplicirane vrijednosti nisu dozvoljene.",//no i18n
	"crm.duplicate.value.available": "Otkriven je postoje\u0107i {0} s istim {1}.",//no i18n
	"crm.duplicate.value.available.multiple": "Otkriveno je vi\u0161e od jednog {0} s istim  {1}.",//no i18n
	"crm.custombutton.nobuttons.found": "Nema prona\u0111enih gumba",//no i18n
	"crm.custombutton.create.newbutton": "Stvorite gumb",//no i18n
	"crm.custombutton.manage.button": "Upravljanje gumbima",//no i18n
	"crm.custombutton.name": "Ime",//no i18n
	"crm.customize.custombutton.function.desc": "Opis",//no i18n
	"crm.custombutton.action": "Radnja gumba",//no i18n
	"crm.custombutton.empty.field.values": "Prazna vrijednost prona\u0111ena je za ova polja?",//no i18n
	"crm.custombutton.proceed.action": "Sigurni ste da i dalje \u017eelite nastaviti?",//no i18n
	"zb.common.Cancel": "Otka\u017ei",//no i18n
	"crm.yes.proceed": "Da, nastavi",//no i18n
	"crm.label.module.merge": "Spojite {0}",//no i18n
	"crm.view.record": "Prika\u017ei {0}",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "Odaberite datoteku",//no i18n
	"crm.label.subform.addrows": "Dodaj red",//no i18n
	"crm.button.save&new": "Spremi i Novo",//no i18n
	//end-cx create form keys
	"Jan" : "Sij",//No I18n
	"Feb" : "Velj",//No I18n
	"Mar" : "O\u017eu",//No I18n
	"Apr" : "Tra",//No I18n
	"Jun" : "Lip",//No I18n
	"Jul" : "Srp",//No I18n
	"Aug" : "Kol",//No I18n
	"Sep" : "Ruj",//No I18n
	"Oct" : "Lis",//No I18n
	"Nov" : "Stu",//No I18n
	"Dec" : "Pro",//No I18n
	"crm.mb.newversion.msg4" : "U redu, shva\u0107am!",//No I18n
	"crm.label.More" :"Vi\u0161e", //no i18n

	"crm.label.unmapped.stages":"Nije uzeto u obzir", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Poka\u017ei vi\u0161e",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Poka\u017ei manje",//No I18n

	 	 //filter related keys-start
	"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
	"crm.lead.prediction.tooltip.convert":"VJEROJATNOST",//no i18n
	"crm.lead.prediction.tooltip.score":"REZULTAT",//no i18n
	"Planned":"Planirano",//no i18n
	"Invited":"Pozvano",//no i18n
	"Sent":"Poslano",//no i18n
	"Received":"Primljeno",//no i18n
	"Opened":"Otvoreno",//no i18n
	"Responded":"Odgovoreno",//no i18n
	"Bounced":"Odbijeno",//no i18n
	"Opted\ Out":"Izuzeto",//no i18n
	"crm.filter.label.with.open":"S otvorenim {0}",//no i18n
	"crm.filter.label.without.open":"Bez otvorene {0}",//no i18n
	"crm.filter.label.without.any":"Bez {0}",//no i18n
	"crm.filter.label.with.module":"S {0}",//no i18n
	"crm.filter.label.activity.due":"{0} Uslijed",//no i18n
	"crm.filter.label.activity.done":"{0} Izvr\u0161eno",//no i18n
	"Notes":"Napomene",//no i18n
	"crm.filter.label.notes.added":"Dodane napomene",//no i18n
	"crm.label.filter.email.status":"Najnoviji status e-po\u0161te",//no i18n
	"crm.label.filter.email.clicked":"kliknuto",//no i18n
	"crm.label.filter.email.responded":"odgovoreno", //no i18n
	"crm.label.filter.email.info":"Filtrirajte zapise na temelju posljednjeg statusa va\u0161e poslane/primljene e-po\u0161te.",//no i18n
	"crm.filter.label.sent":"poslano",//no i18n
	"crm.filter.label.not.sent":"nije poslano",//no i18n
	"crm.filter.label.opened":"otvoreno",//no i18n
	"crm.filter.label.not.opened":"nije otvoreno",//no i18n
	"crm.filter.label.received":"primljeno",//no i18n
	"crm.filter.label.not.received":"nije primljeno",//no i18n
	"crm.filter.label.bounced":"vra\u0107eno",//no i18n
	"crm.filter.label.opened.not.replied":"otvoreno i nije odgovoreno", //no i18n
	"crm.filter.label.any":"Bilo \u0161to od navedenog",//no i18n
	"crm.zia.config.potential.amount":"{0} Iznos",//no i18n
	"Quote\ Stage":"{0} Faza",//no i18n
	"crm.module.owner":"{0}: Vlasnik",//no i18n
	"Potential\ Closing\ Date":"{0} Datum zatvaranja",//no i18n
	"crm.lead.prediction.likely.convert":"Vjerojatno \u0107e se pretvoriti",//no i18n
	"crm.lead.prediction.convert.high":"Visoki",//no i18n
	"crm.lead.prediction.convert.medium":"Srednji",//no i18n
	"crm.lead.prediction.convert.low":"Niski",//no i18n
	"crm.predictions.feature.label":"Predvi\u0111anje",//no i18n
	"crm.intelligence.prediction.likelywin":"Vjerojatnost dobitka",//no i18n
	"crm.intelligence.prediction.likelylose":"Vjerojatnost gubitka",//no i18n
	"crm.intelligence.prediction.halfchance":"50:50",//no i18n
	"crm.intelligence.prediction.score":"Predvi\u0111eni rezultat",//no i18n
	"crm.lead.prediction.recent.score":"Posljednji predvi\u0111eni rezultat",//no i18n
	"crm.intelligence.prediction.lastconv":"Posljednja 3 razgovora",//no i18n
	"crm.intelligence.prediction.recordsfocus":"Zapisi na koje se treba fokusirati",//no i18n
	"crm.intelligence.prediction.slowmoving":"Sporo kretanje",//no i18n
	"crm.intelligence.prediction.trend.down":"Nedavno opada trend",//no i18n
	"crm.label.touched.records":"Dotaknuti zapisi",//no i18n
	"crm.label.untouched.records":"Nedotaknuti zapisi",//no i18n
	"crm.label.record.action":"Korak zapisa",//no i18n
	"workflow.rule.view.label.Modified":"Promijenjeno",//no i18n
	"crm.label.not.modified":"Nije izmijenjeno",//no i18n
	"crm.label.related.records.action":"Povezani koraci zapisa",//no i18n
	"Done":"Gotovo",//no i18n
	"crm.label.not.done":"Nije napravljeno",//no i18n
	"sentiment.model":"Tendencija e-po\u0161te",//no i18n
	"sentiment.criteria.count":"Broj",//no i18n
	"sentiment.criteria.percentage":"Postotak",//no i18n
	"sentiment.criteria.lastmail":"Za posljednju e-po\u0161tu",//no i18n
	"Chats":"Razgovori",//no i18n
	"Attended":"Sudjelovao",//no i18n
	"crm.lead.prediction.popup.text":"Ako \u0107e se vjerojatno pretvoriti {0}, predvi\u0111eni rezultat treba biti izme\u0111u {1}.",//no i18n
	"crm.lead.prediction.popup.final":"Promijenite filtar i poku\u0161ajte ponovno.",//no i18n
	"crm.custom.field.less.than.to1":"Raspon Od ne mo\u017ee biti ve\u0107i od raspona Do.",//no i18n
	"Last\ Activity\ Date":"Datum posljednje aktivnosti",//no i18n
	"crm.label.vendor.name":"{0} Ime",//no i18n
	"hours":"sati",//no i18n
	"days":"dana",//no i18n
	"weeks":"tjedni",//no i18n
	"months":"mjeseci",//no i18n
	"years":"godina",//no i18n
	"crm.label.general.small.after":"nakon",//no i18n
	"Last\ Week":"Pro\u0161li tjedan",//no i18n
	"Last\ Month":"Posljednji mjesec",//no i18n
	"crm.module.name":"{0} Ime",//no i18n
	"Campaign":"Kampanja",//no i18n
	"Tasks":"Zadaci",//no i18n
	"Calls":"Pozivi",//no i18n
	"Events":"Doga\u0111aji",//no i18n
	"sentiment.criteria.wrongcriteria":"Vrijednost kriterija ne mo\u017ee biti ve\u0107a od {0}",//no i18n
	"crm.chosen.minimum.input.text":"Unesite najmanje {0} znakova",//no i18n
	"crm.intelligence.prediction.trendup":"Trend pove\u0107anja",//no i18n
	"crm.intelligence.prediction.trenddown":"Trend smanjivanja",//no i18n
	"crm.zia.prediction.notrend":"No trend",//no i18n
	"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
	"Completed":"Zavr\u0161eno" ,//no i18n
	"crm.label.success":"Uspjeh" ,//no i18n
	"crm.label.Failure":"Neuspjeh" ,//no i18n
	"Both":"Oboje" ,//no i18n
	"crm.condition.cannot.empty":"Uvjet ne mo\u017ee biti prazno polje.",//no i18n
	"crm.condition.last.30.days":"posljednjih 30 dana",//no i18n
	"crm.condition.last.60.days":"posljednjih 60 dana",//no i18n
	"crm.condition.last.90.days":"posljednjih 90 dana",//no i18n
	"crm.sentiment.Positive":"Pozitivno",//no i18n
	"crm.sentiment.Negative":"Negativno",//no i18n
	"sentiment.positiveandnegative":"Pozitivno i negativno",//no i18n
	"sentiment.positiveornegative":"Pozitivno ili negativno",//no i18n
	"sentiment.positiveonly":"Samo pozitivno",//no i18n
	"sentiment.negativeonly":"Samo negativno",//no i18n
	"crm.sentiment.Neutral":"Neutralno",//no i18n
	"crm.filters.select.campaign.type":"Odaberite {0} vrstu",//no i18n
	"crm.filters.select.campaign.status":"Odaberite {0} status",//no i18n
	"campaign.Member" : "\u010clan",//no i18n
	"Service":"Usluga",//no i18n
	"Activities":"Aktivnosti",//no i18n
	"crm.livedesk.pot.nextdays":"Sljede\u0107ih {0} dana",//no i18n
	"Today\ +\ Overdue":"Danas + S prekora\u010denim rokom",//no i18n
	"crm.source.user.and.system":"Korisnik i sustav",//no i18n
	"crm.source.user.or.system":"Korisnik ili sustav",//no i18n
	"User":"Korisnik",//no i18n
	"crm.source.user.only":"Samo po korisnicima",//no i18n
	"crm.source.system.only":"Samo preko sustava",//no i18n
	"Scheduled":"Planirano",//no i18n
	"Attended\ Dialled":"Nadziran birani broj",//no i18n
	"Unattended\ Dialled":"Nenadziran birani broj",//no i18n
	"Cancelled":"Otkazano",//no i18n
	"crm.filter.email.isblocked":"blokirano",//no i18n
	"crm.filter.email.isnotblocked":"nije blokirano",//no i18n
	"condition.till.now":"Do sada",//no i18n
	"crm.recurring.no.months":"{0} mjeseci",//no i18n
	"crm.lead.prediction.tooltip":"Vjerojatno \u0107e se pretvoriti - raspon rezultata",//no i18n
	"crm.website.activity":"Aktivnost web-mjesta",//no i18n
	"crm.label.More":"Vi\u0161e",//no i18n
	"crm.label.By":"Od",//no i18n
	"crm.chosen.searching.text":"Pretra\u017eivanje...",//no i18n
	"crm.label.memberstatus.is":"i status \u010dlana je",//no i18n
	"crm.events.duration":"Trajanje",//no i18n
	"crm.title.clear.name":"Izbri\u0161i",//no i18n
	"crm.label.status.is":"i status je",//no i18n
	"zia.last3.help":"Razgovor uklju\u010duje pozive, zadatke, {0}, primljenu e-po\u0161tu, napomene, komentare na dru\u0161tvenim mre\u017eama, zahtjeve podr\u0161ke s usluge Desk.",//no i18n
	"crm.label.tag.related.to":"povezane s",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Bit \u0107e stvoren novi {0}.",//No I18n
	"crm.krp.no.records.found" : "{0} nisu prona\u0111eni(e)",//No I18n
	"crm.module.new" : "Novo {0}",//No I18n
	"crm.label.view" : "Prika\u017ei",//No I18n
	"crm.nsocial.customers" : "Korisnici",//No I18n
	"crm.nsocial.open.potential" : "Otvori {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Ostalo",//No i18n
	"crm.field.length.check" : "{0} vrijednost prema\u0161uje maksimalnu du\u017einu.", //No I18n
	"crm.lower.now":"sada",//no i18n
	"crm.time.min.ago":"prije {0} minuta",//no i18n
	"crm.time.mins.ago":"prije {0} minuta",//no i18n
	"crm.time.hr.ago":"prije {0} sat",//no i18n
	"crm.time.hrs.ago":"prije {0} sati", //no i18n
	"AllUsers": "Svi korisnici", //no i18n
	"crm.label.search":"Pretra\u017ei",//no i18n
	"crm.api.filterby":"Filtriraj po",//no i18n
	"crm.customview.nofields.found":"--Nema podudaraju\u0107ih polja--",//no i18n
	"crm.setup.system.ziarecommendation":"Preporuka",//no i18n
	"crm.filter.label.all.products":"Sve {0}",//no i18n
	"crm.filter.label.select.products":"Odabrano {0}",//no i18n
	"crm.reviewprocess.smart.filter":"Status zapisa u procesu pregledavanja",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Odaberite vrijednost.",//no i18n
	"crm.segmentation.segment.score":"Ocjena segmenta",//no i18n
	"crm.filter.label.in":"na temelju",//no i18n
	"crm.filter.label.and.purchase.in":"i vjerojatno \u0107e kupiti",//no i18n
	"crm.filter.label.last.purchased":"i nedavno je pribavio",//no i18n
	"crm.filter.label.a.day":"dnevno",//no i18n
	"crm.filter.label.a.week":"tjedno",//no i18n
	"crm.filter.label.a.month":"mjese\u010dno",//no i18n
	"crm.cal.custom":"Prilago\u0111eno",//no i18n
	"crm.mb.field.common.empt":"Vrijednost ne mo\u017ee biti prazna.",//no i18n
	"crm.chosen.error.loading.text":"Ups, nismo mogli u\u010ditati va\u0161e rezultate",//no i18n
	"crm.filter.label.firstbuy":"Prvi put",//no i18n
	"crm.filter.label.cwbab":"Ovisne stavke",//no i18n
	"crm.filter.label.fbt":"Paket",//no i18n
	"crm.filter.label.rebuy":"Ponovi",//no i18n
	"crm.filter.label.nextbuy":"Niz",//no i18n
	"crm.mxnlookup.select" : "Dodijeli {0}",//No I18n
	"crm.lookup.chooserecord":"Odaberi {0}",//no i18n
	"crm.record.selected":"Odabrani {0}",//no i18n
	"crm.module.empty.message" : "{0} nisu prona\u0111eni(e)",//No I18n
	"crm.mxnlookup.selected" : "Dodijeljeno {0}",//No I18n
	"crm.security.error" : "Nemate dovoljno dozvola za provo\u0111enje ovog postupka. Obratite se svom administratoru.", //no i18n
	"crm.label.creator.noPermission" : "Dozvola je odbijena", //no i18n
	"crm.segmentation.recency" : "Nedavni doga\u0111aji", //no i18n
	"crm.segmentation.frequency" : "U\u010destalost", //no i18n
	"crm.segmentation.monetary " : "Financije", //no i18n
	"crm.smartfilter.related.module.msg" : "Ne mo\u017eete odabrati vi\u0161e od tri vezana modula." , //no i18n
	"crm.smartfilter.related.module.msg1" : "(Primjer: e-po\u0161ta, aktivnosti, bilje\u0161ke)" , //no i18n
	"crm.smartfilter.related.module.msg2"  : "Trajanje ne mo\u017ee biti prazno polje", //no i18n
	"crm.label.timeZone": "Vremenska zona", //NO I18n
	"crm.label.insufficient.privileges": "Nedovoljne ovlasti za provo\u0111enje ovog postupka. Kontaktirajte administratora.", //NO I18n
	"crm.filter.header.secton.system": "Filtri definirani sustav", //NO I18N
	"crm.filter.header.secton.fields": "Filtriraj prema poljima", //NO I18N
	"crm.createfield.calcinfo.new" : "Ovo polje djeluje kao digitron za svaki izraz koji unesete.</br> <b>Npr. 20+20</b> \u0107e automatski dati <b>40</b>",//No i18n
	"crm.lable.read.only" : "Polje samo za \u010ditanje",//No i18n
	"crm.column.sort.asc" : "Ulaz",//No I18n
	"crm.column.sort.desc" : "Silaz",//No i18n
	"crm.column.unsort" : "Nesortirano",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Podijeli s korisnikom", //NO I18N
	"crm.label.edited":"Ure\u0111eno",//no i18n
	"crm.label.edited.on":"Datum ure\u0111ivanja",//no i18n
	"crm.message.limit.exceed": "Dopu\u0161teno se samo {0} znakova za {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Dijeljeno s korisnikom", //NO I18N
	"crm.button.ok" : "U redu", //NO I18N
	"crm.role.already.selected" : "Ova uloga ve\u0107 je odabrana", //no i18n
	"crm.user.deleted": "KORISNIK JE IZBRISAN",  //NO I18N
	"crm.account.closed": "OVAJ RA\u010cUN JE ZATVOREN",  //NO I18N
	"crm.start.chat": "Zapo\u010dni razgovor",  //NO I18N
	"crm.start.call": "Zapo\u010dni poziv",  //NO I18N
	"crm.recipient.invalid.email" : "Prona\u0111ena je neva\u017ee\u0107a e-po\u0161ta.", //NO I18N
	"crm.recipient.add.recipient" : "Dodaj jo\u0161 jednog primatelja", //NO I18N
	"crm.start.video.call": "Zapo\u010dni videopoziv",  //NO I18N //ignorei18n_start

	"Score":"Rezultat",
	"Positive Score":"Pozitivan rezultat",
	"Negative Score":"Negativan rezultat",
	"Touch Point Score":"Rezultat dodirne to\u010dke",
	"Positive Touch Point Score":"Pozitivan rezultat dodirne to\u010dke",
	"Negative Touch Point Score":"Negativan rezultat dodirne to\u010dke",
	"crm.label.scoring.rules":"Pravila ocjenjivanja",
	"crm.label.type.minutes": "Utipkajte ovdje u minutama", //NO I18Ns

	"is\ OPEN":"je OTVOREN",//no i18n
	"is\ WON":"je DOBIVEN",//no i18n
	"is\ LOST":"je IZGUBLJEN",//no i18n
	"crm.potential.all.open":"Sve otvorene faze",//no i18n
	"crm.potential.all.won":"Sve zatvorene dobivene faze",//no i18n

	"crm.potential.all.lost":"Sve zatvorene izgubljene faze",//no i18n
	"crm.campaign.member.status" : "Status \u010dlana",//no i18n
	"crm.dashboard.select.type" : "Odaberi {0}",//no i18n
	"crm.campaign.service.status":"Status usluge",//no i18n

	"crm.label.addColumn":"Dodaj stupac",//no i18n
	"crm.button.clear.filter":"Zatvori filtar",//no i18n
	"crm.button.show.filter":"Poka\u017ei filtar",//no i18n
	"crm.las.error.user.maxlimit":"Mo\u017eete odabrati najvi\u0161e 20 korisnika.",//no i18n
	"crm.las.error.picklist.maxlimit":"Mo\u017eete odabrati najvi\u0161e 20 opcija.",//no i18n

	"crm.fileuploader.message.responseerror": "U\u010ditavanje nije uspjelo.", //NO I18N
	"crm.storage.create.error":"Novi zapisi mogu se kreirati jer ste dostigli svoje maksimalno ograni\u010denje za pohranu podataka.",//no i18n
	"crm.storage.create.error.client":"Novi zapisi ne mogu se kreirati jer je va\u0161 administrator dostigao njegovo maksimalno ograni\u010denje memorije. Kontaktirajte s {0} da biste rije\u0161ili ovaj problem.",//no i18n
	"crm.storage.avail.info":"({0} preostalo od {1})",//no i18n
	"crm.storage.error.key.manage":"Upravljajte pohranom svojih podataka",//no i18n
	"Records":"Zapisi",//no i18n
	"crm.workflow.alert.additional.recipients" : "Dodatni primatelji", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Koristite zarez kako biste razdvojili dodatne adrese e-po\u0161te.", //NO I18N
	"crm.related.contact.account" : "{0} vezano uz {1}",//No I18n
	"crm.allcontact.show" : "Sve {0}",//No I18n
	"crm.button.mass.show" : "Prika\u017ei",//No I18n
	"crm.msg.custom.view.not.replied" : "Neodgovorene poruke", //NO I18N
	"crm.msg.custom.view.replied" : "Odgovorene poruke",//NO I18N
	"crm.workflow.select.recipients" : "Primatelji", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Odaberite barem jedan profil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u017dao nam je, ne mo\u017eete ukloniti zadani profil.",//NO I18N
	"crm.inv.label.add.emails" : "Dodaj poruke e-po\u0161te", //NO I18N
	"crm.prediction.analytics.filter.year":"Pro\u0161la godina",//no i18n
	"Previous\ FY":"Prethodna financijska godina",//no i18n
	"Current\ FY":"Trenutna financijska godina",//no i18n
	"Next\ FY":"Sljede\u0107a financijska godina",//no i18n
	"Current\ FQ":"Trenutni kvartal",//no i18n
	"Next\ FQ":"Sljede\u0107i kvartal",//no i18n
	"Previous\ FQ":"Prethodni financijski kvartal",//no i18n
	"crm.picklist.sample.text":"Ogledni tekst",//no i18n
	"crm.more.colors":"Vi\u0161e boja",//no i18n
	"crm.button.back.alone":"Natrag",//no i18n
	"crm.field.label.email":"E-po\u0161ta",//no i18n
"crm.zia.nba.feature.label":"Sljede\u0107a najbolja radnja",//no i18n
"Meeting":"Sastanak",//no i18n
"Tomorrow":"Sutra",//no i18n
"crm.gdpr.notavailable.field":"Nije dostupno",//no i18n
	"crm.setup.system.ziasimilarity":"Preporuka za sli\u010dnost",//no i18n
	"crm.gdpr.notavailable.field":"Nije dostupno",//no i18n
	"crm.label.simply.in":"u",//no i18n
	"crm.filter.label.all.products":"Sve {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Nazivi segmenta", // NO I18N
	"abm.abm.segment.name": "Naziv segmenta ABM", // NO I18N
	"abm.segmentation.techniques": "ABM tehnike segmentacije", // NO I18N
	"crm.column.list.max.group.limit" : "", //no i18n
	"abm.filters": "ABM filtri", // NO I18N
	"abm.filters.info": "Ovi \u0107e filtri prikazati informacije temeljene na {0} povezanom s {1}.", // NO I18N
	"abm.rfm.label": "RFM oznaka", // NO I18N
	"abm.firmographics.label": "Oznaka firmografije" ,// NO I18N
	"abm.recommendation.label": "Oznaka preporuke", // NO I18N
	"abm.voc.label": "VOC oznaka", // NO I18N
	"abm.engagement.label": "Oznaka anga\u017emana", // NO I18N
	"abm.overall.score": "Ukupan rezultat", // NO I18N
	"abm.mood.score": "Ocjena raspolo\u017eenja", // NO I18N
	"abm.value.score": "Ocjena vrijednosti", // NO I18N
	"abm.engagement.score": "Ocjena anga\u017emana", // NO I18N
	"abm.rfm.score": "RFM ocjena", // NO I18N
	"crm.customview.pin.column":"Zakva\u010di stupac",//no i18n
"crm.customview.unpin.column":"Otkva\u010di stupac",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} treba biti veći od ili jednak {1}.",
	"crux.users.selected.plural" : "Odabrano korisnika: {0}.",
	"crux.user.selected.singular" :"{0} odabrani korisnik.",
	"crux.criteria.empty.secondaryfield.module" : "Nije pronađeno podudarajuće {0} polje u modulu {1}",
	"crux.criteria.empty.secondaryfield" : "Nije drugo polje {0} dostupno za uspoređivanje, unesite vrijednost za uspoređivanje.",
	"crux.logged.in.role.definition" : "Uloga korisnika koji pokreće radnje bilježenja",
	"zia.similarity.smartfilter.score":"Ocjena sli\u010dnosti",//no i18n
	"zia.similarity.smartfilter.records":"Sli\u010dno {0} od",//no i18n
	"zia.similarity.smartfilter.records.search":"Prika\u017ei sli\u010dno {0} od",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Ne mo\u017eete odabrati vi\u0161e od {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} treba biti veći od ili jednak {1}.",
	"crux.users.selected.plural" : "Odabrano korisnika: {0}.",
	"crux.user.selected.singular" :"{0} odabrani korisnik.",
	"crux.criteria.empty.secondaryfield.module" : "Nije pronađeno podudarajuće {0} polje u modulu {1}",
	"crux.criteria.empty.secondaryfield" : "Nije drugo polje {0} dostupno za uspoređivanje, unesite vrijednost za uspoređivanje.",
	"crux.logged.in.role.definition" : "Uloga korisnika koji pokreće radnje bilježenja",
	"crux.max.limit.unselect" : "Ne možete odabrati više od {0} {1}.", //NO I18N
	"crux.existing.tag" : "Već je odabrano \u201e{0}\u201d", //No I18N
	"crm.label.true" : "To\u010dno",//NO I18N
	"crm.label.false" : "Neto\u010dno",//NO I18N
	"crm.record.locked" : "Zaklju\u010dano",//NO I18N
	"crm.filter.number.range":"vrijednosti od {0}-{1}",//no i18n
	"crm.smartfilter.picklist.options.msg":"Ne mo\u017eete odabrati ovu opciju za vi\u0161e od 10 izabranih vrijednosti.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"Ne mo\u017eete odabrati vi\u0161e od 5 {0}",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Ne mo\u017eete odabrati vi\u0161e od {0} {1}",//no i18n
	"crm.best.time.column.label":"Najbolje vrijeme za kontakt",//no i18n
	"crm.competitoralert.mentioned.in.email":"Spominje se u <b>e-po\u0161ti</b>",//no i18n
	"crm.competitoralert.with.sentiment":"Sa sklonosti",//no i18n
	"crm.competitoralert.name.error":"Unesite valjanu vrijednost za ime natjecatelja.",//no i18n
	"crm.competitoralert.date.error":"Navedite valjani datum za spominjanje konkurenta u e-poruci.",//no i18n
	"crm.competitoralert.sentiment.error":"Odaberite valjanu opciju za mi\u0161ljenje konkurenata.",//no i18n
	"crm.competitor.name":"Konkurent",
"crm.lookup.advance.error.msg" : "Dogodio se problem prilikom primjene filtra. Osvje\u017eite stranicu i poku\u0161ajte ponovno.",
	"crm.label.add.tags":"Dodaj oznake",//no i18n
	"crm.confirm.deassociate":"Poni\u0161ti dodjelu",//no i18n
	"Contact\ Name" : "{0} Ime",
	"Lead\ Name" : "{0} Ime",
	"-None-" : "Nijedno",
	"January":"Sije\u010danj",
	"February":"Velja\u010da",
	"March":"O\u017eujak",
	"April":"Travanj",
	"May":"Svibanj",
	"June":"Lipanj",
	"July":"Srpanj",
	"August":"Kolovoz",
	"September":"Rujan",
	"October":"Listopad",
	"November":"Studeni",
	"December":"Prosinac",
	// Image Upload Open
	"crm.label.browse.files":"Pretra\u017ei datoteke",//no i18n
	"crm.label.lowercase.or":"ili",//no i18n
	"crm.image.header":"Slika",//no i18n
	"crm.image.n.n":"Omjer N:N",//no i18n
	"crm.image.2.2":"Omjer 2:2",//no i18n
	"crm.image.4.3":"Omjer 4:3",//no i18n
	"crm.image.16.9":"Omjer 16:9",//no i18n
	"crm.image.height.width.swap":"Zamijeni visinu i \u0161irinu",//no i18n
	"crm.image.rotate.image":"Rotiraj",//no i18n
	"crm.label.lowercase.of":"od",//no i18n
	"crm.image.empty.name.placeholder" : "Unesite naziv",
	"crm.image.crop.and.rotate" : "Izre\u017ei i rotiraj",
	"crm.view.attachment.download" : "",
	"crm.image.description" : "Dodaj opis...",
	"crm.image.actual.size" : "Stvarna veli\u010dina",
	"crm.image.reset" : "Resetiraj",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"Preuzmi",//no i18n
	"crm.label.edit":"Uredi",//no i18n
	"crm.label.close":"Zatvori",//no i18n
	"crm.label.any":"Svaki",
	"crm.image.description":"Dodaj opis...",//no i18n
	"crm.image.zoom.in":"Pove\u0107aj",//no i18n
	"crm.image.zoom.out":"Smanji",//no i18n
	"crm.label.lowercase.of":"od",//no i18n
	"crm.image.desc.maxsize":"Opis ne smije prelaziti 255 znakova.",//no i18n
	"crm.image.name.maxsize":"Naziv ne smije prelaziti 100 znakova.",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"Veli\u010dina datoteke prelazi dopu\u0161teno ograni\u010denje od {0}.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"Mo\u017eete prenijeti maksimalno {0} datoteka.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"Mo\u017eete prilo\u017eiti maksimalno {0} datoteka.",//no i18n
	"crm.ImageuploadField.size.limit":"Ukupna veli\u010dina slika prelazi dozvoljeno ograni\u010denje od {0} MB.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"Mo\u017eete odabrati samo 1 sliku",//no i18n
	"crm.image.crop.error":"Ne mo\u017ee se izrezati slika, poku\u0161ajte ponovno!",//no i18n
	"crm.attach.here":"ovdje.",//no i18n
	"crm.image.unsupported.corrupted.single":"O\u0161te\u0107ena je fotografija koju poku\u0161avate u\u010ditati.",//no i18n
	"Browse":"Pregledavanje",//no i18n
	"crm.imageupload.failure":"Neke slike nisu mogle biti u\u010ditane. Poku\u0161ajte ponovno kasnije.",//no i18n
	"zc.editor.width":"\u0160irina",//no i18n
	"ze.editor.height":"Visina",//no i18n
	"crm.label.delete":"Izbri\u0161i",//no i18n
	"crm.image.error.gif":"GIF slika ne mo\u017ee se rezati.",//no i18n
	"crm.fileuploader.message.morefiles":"{0} ostale datoteke",//no i18n
	"crm.fileuploader.message.invalidfileType1":"Datoteka {0} nije podr\u017eana.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"Datoteke {0} i {1} nisu podr\u017eane.",//no i18n
	"crm.attach.upload.sizelimit":"Ograni\u010denje ukupne veli\u010dine",//no i18n
	
	
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "Mo\u017eete prenijeti maksimalno {0} datoteka.",
	"crm.file.upload.maxlength.exceeds" : "Mo\u017eete u\u010ditati samo jednu datoteku u {0}."


}
